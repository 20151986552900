<template>
  <v-card>
    <v-card-title>
      <div class="d-flex align-center">
        <v-icon
          aria-hidden="true"
          class="mr-3 material-icons-outlined"
          color="#1D428A"
        >
          notifications
        </v-icon>
        <h2 class="title font-weight-bold text-subtitle-2 text-sm-h6">
          Compliance Reminders
        </h2>
      </div>
    </v-card-title>
    <hr>
    <v-card-text class="pa-0">
      <v-data-table
        hide-default-footer
        class="pa-0"
        :headers="complianceHeaders"
        :items="complianceData"
        v-bind="$attrs"
      >
        <template v-slot:item.name="{ item }">
          <v-icon
            aria-hidden="true"
            small
            class="mr-1"
          >
            mdi-open-in-new
          </v-icon>
          <a
            :href="item.url"
            target="_blank"
          >{{ item.name }}</a>
        </template>
        <template v-slot:item.due="{ item }">
          <div class="d-flex justify-end align-center">
            <div>
              <v-icon
                aria-hidden="true"
                class="error--text"
                v-if="isDateExpired(item.due) && !isCloseToDate(item.due)"
              >
                error
              </v-icon>
              <v-icon
                aria-hidden="true"
                class="warning--text"
                v-if="isCloseToDate(item.due)"
              >
                mdi-alert-circle-outline
              </v-icon>
            </div>
            <div
              class="fix-tr-width text-end"
              :class="dueDateClass(item.due)"
            >
              {{ item.due }}
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div
            class="d-flex nesa-fees-balance"
            v-if="userProfile.CurrentBalance !== null"
          >
            <td>
              <v-icon
                aria-hidden="true"
                small
                class="mr-1"
              >
                mdi-open-in-new
              </v-icon>
              <a
                :href="dashboardContent?.nesa_fee_url"
                target="_blank"
              >NESA Fees Outstanding</a>
            </td>
            <div
              v-if="isBalancePositive"
              class="d-flex align-end"
            >
              <v-icon
                aria-hidden="true"
                class="error--text"
              >
                error
              </v-icon>
              <div class="error--text fix-tr-width text-end">
                ${{ userProfile.CurrentBalance }}
              </div>
            </div>
            <div v-else>
              ${{ userProfile.CurrentBalance }}
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        depressed
        color="black"
        target="_blank"
        :href="goToEtamsUrl"
        style="border: 2px solid black"
      >
        <v-icon aria-hidden="true" size="24" left>
          mdi-open-in-new
        </v-icon>
        Go to eTAMS
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

const ComplianceReminders = {
    data() {
      return {
        complianceHeaders: [
          { text: 'Name', value: 'name' },
          { text: 'Due Date', value: 'due' },
        ],
        goToEtamsUrl: "https://etams.nesa.nsw.edu.au/saml/login/det/starthere"
      }
    },
    methods: {
      isDateExpired(itemDate){
        let itemCorrectDate = itemDate.split("/");
        itemCorrectDate = itemCorrectDate[2]+'/'+ itemCorrectDate[1] + '/' + itemCorrectDate[0];
        let itemDue = moment(itemCorrectDate);
        return itemDue.diff(moment(),'days') <= 0;
      },
      isCloseToDate(itemDate) {
        let itemCorrectDate = itemDate.split("/");
        itemCorrectDate = itemCorrectDate[2]+'/'+ itemCorrectDate[1] + '/' + itemCorrectDate[0];
        let itemDue = moment(itemCorrectDate);
        return itemDue.diff(moment(),'months') < 3 && itemDue.diff(moment(),'days') > 0;
      },
      dueDateClass(item) {
        if(this.isCloseToDate(item)) {
          return 'warning--text font-weight-bold'
        } else if(this.isDateExpired(item)) {
          return 'error--text font-weight-bold'
        }
        return '';
      },
    },

    computed: {
      ...mapGetters({
        userProfile:"moduleUserProfile/userProfile",
        isGainingAccreditation: "moduleUserProfile/isGainingAccreditation",
        isMaintainingAccreditation: "moduleUserProfile/isMaintainingAccreditation",
      }),
      ...mapState('moduleStaticContent', {
        dashboardContent: (state) => state.content.dashboard,
      }),
      isBalancePositive() {
        return this.userProfile.CurrentBalance > 0;
      },
      complianceData() {
        const data = [];
        if (this.isGainingAccreditation) {
          data.push({ name: 'Achieve Proficient Teacher by', due: this.$options.filters.dateFormat(this.userProfile.AchievePTBy), url: this.dashboardContent?.proficient_teacher_url },);
        } else {
          data.push({ name: 'Accreditation due date', due: this.$options.filters.dateFormat(this.userProfile.MOACurrentPeriodEndDate), url: this.dashboardContent?.accreditation_progress_url },);
        }
        data.push({ name: 'WWCC expiry date (source: eTAMS)', due: this.$options.filters.dateFormat(this.userProfile.WWCExpiryDate), url: this.dashboardContent?.etams_url });
        return data;
      }
    }
}
export default ComplianceReminders;
</script>

<style lang="scss" scoped>
  .nesa-fees-balance {
    padding: 0 16px;
    font-size: 0.875rem;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    font-weight: bold;
    background: #EBEBEB;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  .fix-tr-width {
    width: 95px;
  }

  ::v-deep {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child {
      color: #002664!important;
      text-align: end!important;
    }

    .v-data-table-header-mobile {
      display: none;
    }

    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 16px;
    }
  }

</style>
