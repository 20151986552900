<template>
  <div
    class="white--text status-container-print"
    style="
      height: 100%;
      background-image: url('images/background.jpg');
      background-size: cover;
    "
  >
    <!--  For each View component, follow Vuetify <v-container> => <v-row> => <v-col> grid system  -->
    <v-container v-if="showDashboard" class="container-main" fluid>
      <div class="d-flex justify-space-between pt-2 pl-2 pl-sm-0 mb-4">
        <div class="d-flex">
          <!-- Avatar -->
          <div class="align-self-center d-none d-md-block">
            <v-avatar
              round
              size="100"
              color="#C7DCF0"
              class="mr-5"
              style="color: #002664; font-size: 30px"
            >
              <img v-if="userAvatar" :src="userAvatar" alt="profile image" />
              <span v-else>
                {{ userInitialName }}
              </span>
            </v-avatar>
          </div>
          <!-- ... Avatar -->

          <div class="d-flex flex-column">
            <!-- Teacher's Summary details -->
            <div class="text-sm-h5 font-weight-bold">
              {{ userProfile.FirstName }} {{ userProfile.LastName }},
              {{ userProfile.MainEducationFacility }}
            </div>
            <div class="my-1">
              <strong>Accreditation Level:</strong>
              {{ userProfile.AccreditationStatus }}
            </div>
            <div class="my-1" v-if="isMaintainingAccreditation">
              <strong>Maintenance Period:</strong>
              {{ userProfile.MOACurrentPeriodStartDate | dateFormat }} –
              {{ userProfile.MOACurrentPeriodEndDate | dateFormat }}
            </div>
            <div class="my-1" v-if="isGainingAccreditation">
              <strong>Proficient Teacher Period:</strong>
              {{ userProfile.StartDateAchievePT | dateFormat }} –
              {{ userProfile.AchievePTBy | dateFormat }}
            </div>
            <!-- ... Teacher's Summary details -->
          </div>
        </div>

        <div class="d-flex flex-column align-end">
          <!-- My Details Btn -->
          <div class="pt-1">
            <v-btn
              class="ml-0 ml-md-8 border-btn"
              text
              large
              color="white"
              :to="{ name: 'StaffDetails' }"
            >
              <v-icon aria-hidden="true" left size="24"> person_pin </v-icon>
              My Details
            </v-btn>
            <!-- ... My Full Details Btn -->
          </div>

          <!-- Print Btn -->
          <div class="ml-auto d-print-none d-none d-sm-block">
            <v-btn text large color="white" @click="printStatusContents">
              <v-icon aria-hidden="true" left size="24"> print </v-icon>
              Print
            </v-btn>
          </div>
          <!-- ... Print Btn -->
        </div>
      </div>
      <!-- Notifications -->
      <div
        v-for="(notification, iNotification) in bannerNotifications"
        :key="iNotification"
        class="mt-5"
      >
        <Alert
          showAlert
          class="d-print-none"
          type="warning"
          allowDismiss
          :htmlText="`<div>${
            notification.messageTitle
          }</div> <div class='font-weight-regular'>${
            notification.htmlBody ? notification.htmlBody : ''
          }</div>`"
          :actionHandler="actionHandler"
          inPage
        >
          <template v-slot:close>
            <v-btn
              @click="deleteNotificationById(notification)"
              icon
              aria-label="Banner notification close"
              class="size--44"
            >
              <v-icon>cancel</v-icon>
            </v-btn>
          </template>
        </Alert>
      </div>
      <!-- ... Notifications -->

      <div v-if="isMaintainingAccreditation" class="row">
        <!--  Professional Development Chart -->
        <!-- <div class="chart col-12 col-md-6">
          <PdHoursChart
            @openChartInfo="openChartInfo"
            @openElectiveForm="openElectiveFormDialog"
            :dashboardContent="dashboardContent"
          />
        </div> -->
        <!-- ... -->

        <!-- MaintenancePeriodCountdown -->
        <div class="chart col-12 col-md-6">
          <MaintenancePeriodCountdown @openChartInfo="openChartInfo" />
        </div>
        <!-- ... -->

        <!-- ComplianceReminders -->
        <div class="grow col-12 col-md-6">
          <ComplianceReminders />
        </div>
        <!-- ... -->
      </div>

      <div class="row">
        <div class="grow col-12 col-md-6">
          <TaskChecklist
            :indexCurrentChecklist="indexCurrentChecklist"
            :isReadOnly="true"
          />
        </div>
        <div class="grow col-12 col-md-6">
          <div class="d-flex flex-column">
            <ComplianceReminders
              v-if="!isMaintainingAccreditation"
            />
            <v-card 
              class="mandatory-training-print" 
              :class="!isMaintainingAccreditation? 'mt-5' : ''"
            >
              <MandatoryTrainingReminders />
              <div class="mandatory-training-table-footer d-print-none">
                <v-btn
                  v-if="dashboardContent"
                  outlined
                  depressed
                  color="black"
                  target="_blank"
                  :href="dashboardContent?.my_pl_url"
                  style="border: 2px solid black"
                >
                  <v-icon aria-hidden="true" size="24" left>
                    mdi-open-in-new
                  </v-icon>
                  Go to MyPL
                </v-btn>
              </div>
            </v-card>
          </div>
        </div>
      </div>

      <!-- Question Mark Icon Modal -->
      <Dialog
        :openDialog="openInfoDetailsDialog"
        maxWidth="600px"
        :title="
          isRadialBar
            ? 'Professional Development (PD) Hours'
            : 'Maintenance Period'
        "
        icon="info_outline"
        :displayCloseBtn="true"
        @close="closeInfoDetailsDialog"
        :return-handler="closeInfoDetailsDialog"
      >
        <div slot="text" class="px-5 ml-14">
          <p
            v-if="dashboardContent"
            v-html="
              isRadialBar
                ? dashboardContent.total_pd_hours_tooltip
                : dashboardContent.maintenance_period_tooltip
            "
          />
        </div>
      </Dialog>
      <!-- ... Question Mark Icon Modal -->

      <v-row>
        <v-col cols="6">
          <QuickLinks class="my-6 d-flex flex-column d-print-none" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { AdsChart, Alert, Dialog } from '@nswdoe/doe-ui-core';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

import ComplianceReminders from './ComplianceReminders';
import PdHoursChart from './PdHoursChart';
import QuickLinks from './QuickLinks';
import TaskChecklist from '../tasks/TaskChecklist.vue';
import MandatoryTrainingReminders from './MandatoryTrainingReminders';
import MaintenancePeriodCountdown from './MaintenancePeriodCountdown.vue';
import { gtmDeleteNotification } from '../../plugins/gtm-utils';

const Status = {
  title: 'My Status - Q+',
  data() {
    return {
      openStaffDetailsDialog: false,
      openInfoDetailsDialog: false,
      showElectiveFormDialog: false,
      showFooter: true,
      isRadialBar: undefined,
      loading: true,
    };
  },
  components: {
    AdsChart,
    Alert,
    Dialog,
    ComplianceReminders,
    MandatoryTrainingReminders,
    PdHoursChart,
    QuickLinks,
    TaskChecklist,
    MaintenancePeriodCountdown,
  },
  computed: {
    ...mapGetters({
      userProfile: 'moduleUserProfile/userProfile',
      isGainingAccreditation: 'moduleUserProfile/isGainingAccreditation',
      isMaintainingAccreditation:
        'moduleUserProfile/isMaintainingAccreditation',
      userInitialName: 'moduleUserProfile/userInitialName',
      userAvatar: 'moduleUserProfile/userAvatar',
      bannerNotifications: 'moduleNotifications/bannerNotifications',
      bannersToBeDeleted: 'moduleNotifications/bannersToBeDeleted',
    }),
    ...mapState('moduleStaticContent', {
      dashboardContent: (state) => state.content.dashboard,
    }),
    indexCurrentChecklist() {
      if (this.isMaintainingAccreditation) return 1;
      else return 0;
    },
    showDashboard() {
      return !_.isEmpty(this.userProfile) && !this.loading;
    },
    userDetails() {
      if (this.$OAuth.data.profile) {
        return this.$OAuth.data.profile;
      }
      return {};
    },
  },
  watch: {
    bannersToBeDeleted: {
      handler: function() {
        if (this.bannersToBeDeleted.length > 0) {
          for (const banner of this.bannersToBeDeleted) {
            this.deleteNotificationById(banner);
            // console.log("banner deleted...");
          }
        }
      }
    },
  },
  methods: {
    actionHandler() {},
    closeStaffDetailsDialog() {
      this.openStaffDetailsDialog = false;
    },
    closeInfoDetailsDialog() {
      this.openInfoDetailsDialog = false;
    },
    closeElectiveFormDialog() {
      this.showElectiveFormDialog = false;
    },
    openElectiveFormDialog() {
      this.showElectiveFormDialog = true;
    },
    openChartInfo(title) {
      this.openInfoDetailsDialog = true;
      this.isRadialBar = title === 'radialBar' ? true : false;
    },
    printStatusContents() {
      window.print();
    },
    deleteNotificationById(notification) {
      this.$store.dispatch(
        'moduleNotifications/deleteNotification',
        notification.requestId
      );
      gtmDeleteNotification(this.$gtm, notification);
    },
  },
  created() {
    this.$store.dispatch('moduleUserProfile/fetchAvatar');
    this.$store.dispatch('moduleStaticContent/fetchStaticContent', 'tasks');
    this.$store.dispatch('moduleNotifications/fetchNotifications'),
      this.$store.commit('SET_IS_LOADING', true);
    this.loading = true;
    Promise.all([
      this.$store.dispatch('moduleUserProfile/fetchUserProfile'),
      this.$store.dispatch(
        'moduleStaticContent/fetchStaticContent',
        'dashboard'
      ),
      this.$store.dispatch('moduleTasks/fetchCompletedTasks'),
    ]).finally(() => {
      this.$store.commit('SET_IS_LOADING', false);
      this.loading = false;
    });
    this.$store.commit('SET_IS_LOADING', true);
    this.$store
      .dispatch('moduleStaticContent/fetchStaticContent', 'resources')
      .finally(() => {
        this.$store.commit('SET_IS_LOADING', false);
      });
  },
};

export default Status;
</script>

<style lang="scss" scoped>
.v-dialog.v-dialog--active {
  height: 100px;
}
.border-btn {
  border: 2px solid $ads-white;
}

//Hide unwanted legend label
.pie-chart ::v-deep {
  .apexcharts-legend-series:nth-child(3) {
    .apexcharts-legend-marker {
      visibility: hidden;
    }
  }
}
.mandatory-training-table-footer {
  padding: 10px;
  width: 100%;
  text-align: end;
  position: static;
  bottom: 0;
}
.chart-icon {
  position: absolute;
  top: 6.5%;
  right: 3%;
  z-index: 2;
  height: 44px;
  width: 44px;
}

.size--44 {
  height: 44px !important;
  width: 44px !important;
}

// ::v-deep .apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series[rel='3']{
//   display:none;
// }
.chart ::v-deep {
  // background-color: #22448b;
  .v-card {
    color: #fff;
    background-color: #22448b;
    flex-grow: 1;

    &__text {
      padding-bottom: 0px;
    }
  }
  .apexcharts-bar-area {
    transform: scale(-1, 1);
    transform-origin: 50% 80%;
    transform-box: fill-box;
  }
}

::v-deep {
  .v-card {
    &.mb-5 {
      margin-bottom: 0 !important;
    }
  }

  hr {
    background-color: #a0a5ae;
    height: 1.5px;
    border: none;
  }
}

//Customized print css
@media print {
  .status-container-print {
    color: black !important;
    background-image: none !important;
  }
  .mandatory-training-print {
    margin-top: 100px !important;
  }
  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }
}
</style>
